import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import { Text, Title } from "../../styles";
import { MyContainer } from "../../styles";
import { Col, Row } from "react-materialize";

export const MeetIfes = () => {
    return(
        <Row>
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                <MyContainer>
                    <Col s={12} m={12} l={6}>
                        <Title>Conheça o IFES</Title>
                        <Text>
                            Com <b>25 campi</b> em funcionamento, incluindo o Centro de 
                            Referência em Formação e em Educação a Distância – Cefor,
                            o Ifes se faz presente em todas as microrregiões 
                            capixabas. Possui ainda, 49 polos de educação a distância, conta com um Polo de Inovação e a Cidade da Inovação. São ofertados desde cursos técnicos a mestrados e possui aproximadamente <b>36 mil alunos</b>. São 95 cursos técnicos, 64 cursos de graduação, 34 especializações, 12 mestrados e 1 doutorado profissional que se iniciou em 2020*.
                        </Text>
                        <Text>
                            <i>*Dados de 2022</i>
                        </Text>
                        
                    </Col>

                    <Col s={12} m={12} l={6}>
                        <iframe frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?q=Av.%20Rio%20Branco%2C%2050%2C%203%C2%BA%20andar%20%E2%80%93%20Santa%20L%C3%BAcia%20%E2%80%93%20Vit%C3%B3ria%20%E2%80%93%20ES%20%E2%80%93%20CEP%3A%2029056-264&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near" title="Av. Rio Branco, 50, 3º andar – Santa Lúcia – Vitória – ES – CEP: 29056-264" aria-label="Av. Rio Branco, 50, 3º andar – Santa Lúcia – Vitória – ES – CEP: 29056-264" data-origwidth="" data-origheight="" style={{height:'300px'}}></iframe>
                    </Col>
                </MyContainer>
            </ScrollAnimation>
        </Row>
    )
}