import React from "react";
import { Header } from "../../components/header";
import { MyContainer, StyledCard, Title, StyledIcon, BigTitle, Text, FlexBox } from "../../styles";
import ScrollAnimation from 'react-animate-on-scroll';


export const Contact = () => {


        const downloadPDF = (filename) => {
          // Lógica para baixar o arquivo PDF com base no nome do arquivo
          
          let url = '';
          if (filename === 'ie') {
                url = './sistema/assets/manual/Manual_Instituição_Externa-Versão_1.0.8.pdf';
                filename = 'Manual_Instituição_Externa-Versão_1.0.8.pdf';
          } else if (filename === 'coord') {
                url = './sistema/assets/manual/Manual_Coordenador-Versão_1.0.8.pdf';
                filename = 'Manual_Coordenador-Versão_1.0.8.pdf';
          } else if (filename === 'admin') {
                url = './sistema/assets/manual/Manual_Administrador-Versão_1.0.8.pdf';
                filename = 'Manual_Administrador-Versão_1.0.8.pdf';
          } else if (filename === 'coord_proj') {
            url = './sistema/assets/manual/Manual_Coordenador_Projeto-Versão_1.0.8.pdf';
            filename = 'Manual_Coordenador_Projeto-Versão_1.0.8.pdf';
          }
             
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          document.body.appendChild(link);
          link.click();
      
          // Limpa o URL criado
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
          }, 0);
        };
    


    return(
        <>
        <Header page='contact'/>
        

        <MyContainer>
            <BigTitle>Manuais do Sistema</BigTitle>
            <FlexBox center>
                <ScrollAnimation animateOnce={true} animateIn="bounceInLeft" >
                        <StyledCard pink className="zoomEffect mouseClick" onClick={() => downloadPDF('ie')}>
                            <StyledIcon className="fa fa-book" aria-hidden="true"></StyledIcon>
                            <Title>Instituição Externa</Title>
                            <Text style={{textAlign:'center'}}>Manual para as instituições externas que queiram criar parcerias </Text>
                        </StyledCard>
                </ScrollAnimation>

                <ScrollAnimation animateOnce={true} animateIn="bounceInRight">
                        <StyledCard pink className="zoomEffect mouseClick" onClick={() => downloadPDF('coord_proj')}>
                            <StyledIcon className="fa fa-file-text" aria-hidden="true"></StyledIcon>
                            <Title>Coordenador de Projeto</Title>
                            <Text style={{textAlign:'center'}}>Manual para o responsável pelas informações de uma parceria </Text>
                        </StyledCard>
                </ScrollAnimation>
            </FlexBox>
            <FlexBox center>
                <ScrollAnimation animateOnce={true} animateIn="bounceInLeft">
                        <StyledCard pink className="zoomEffect mouseClick" onClick={() => downloadPDF('coord')}>
                            <StyledIcon className="fa fa-user" aria-hidden="true"></StyledIcon>
                            <Title>Coordenador Local</Title>
                            <Text style={{textAlign:'center'}}>Manual para o responsável pelas informações de parcerias do Campus </Text>
                        </StyledCard>
                </ScrollAnimation>

                <ScrollAnimation animateOnce={true} animateIn="bounceInRight" >
                        <StyledCard pink className="zoomEffect mouseClick" onClick={() => downloadPDF('admin')} >
                            <StyledIcon className="fa fa-user-plus" aria-hidden="true"></StyledIcon>
                            <Title>Administrador</Title>
                            <Text style={{textAlign:'center'}}>Manual para o responsável pelas informações de parcerias da Reitoria </Text>
                        </StyledCard>
                </ScrollAnimation>
            </FlexBox>

        </MyContainer>

        <MyContainer>
            <BigTitle>Entre em contato através de uma das opções abaixo:</BigTitle>
            <ScrollAnimation animateOnce={true} animateIn='bounceInLeft'>
                <StyledCard pink style={{margin:'auto'}}>
                    <StyledIcon className="fa fa-envelope"/>
                    <Title>parcerias@ifes.edu.br</Title>
                </StyledCard>
            </ScrollAnimation>
        </MyContainer>
        </>
    )
}