import React from "react";
import { Icon, Dropdown } from "react-materialize";
import { Link } from "react-router-dom";
import { Sidenav } from "../sidenav";
import {StyledNavbar} from './styles';
import constants from "../../static";
const LogoImg = require('../../assets/logo.png');


export const PartnersNavbar = () => {
    return (
      <StyledNavbar
        alignLinks="right"
        brand={
          <Link to='/' className="noStyle">
            <img alt="Cabeçalho" src={LogoImg} height={100}></img>
          </Link>
        }
        menuIcon={<Icon>menu</Icon>}
        sidenav={<Sidenav/>}
      >

        <Link to='/seja-nosso-parceiro' >
            <span>
          Seja nosso Parceiro
            </span>
        </Link>

        <Link className="noStyle" to='contato'>
          <Dropdown
              trigger={
              <Link to='/acao-de-pesquisa-e-extensao'>
                Ações de Pesquisa e Extensão 
                <Icon style={{marginTop:"15px"}} right>
                  arrow_drop_down
                </Icon>
              </Link>
              }

              options={{hover:true}}
          >

              <Link to='/acoes-da-prppg'>
              Ações de Pesquisa
              </Link>

              <Link to='/acoes-de-extensao'>
              Ações de Extensão
              </Link>

          </Dropdown>
        </Link>

        <Link to='/contato' >
            <span>
          Contato
            </span>
        </Link>
		
		    <a href={constants.sistema}>
            <span>
          Login
            </span>
        </a>
		

      </StyledNavbar>
    );
} 