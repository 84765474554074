import React from "react";
import { HeaderParallax } from "./styles";
import { StyledCard } from "./styles";
import { Text } from "./text/indes";

export const Header = (props) => {
    const {page} = props;

    return(
        <>
        <HeaderParallax>
            <h2>Plataforma</h2>
            <h1>Parcerias Ifes</h1>
        </HeaderParallax>
        <StyledCard>
            <span className="white-text">
                <Text page={page}/>
            </span>
        </StyledCard>
        </>
    );
 }