import styled from "styled-components";
import { Navbar } from 'react-materialize';

export const StyledNavbar = styled(Navbar)`
    min-height: 100px;
    background-color: var( --e-global-color-text );
    line-height: 100px;
    padding: 0 50px;

    a {
        font-size: 20px;
        font-weight: 500;
        padding: 0 15px;
    }

    .noStyle{
        padding: 0;
        border: none;
    }

    .dropdown-content {
        background-color: var( --e-global-color-text );
        top:-100px;
    }

    .dropdown-content li>a, .dropdown-content li>span{
        color: #fff;
    }

    .dropdown-content li:hover, .dropdown-content li.active{
        background-color: var(--e-global-color-3);
    }

    img{
        padding: 15px 0;
    }

    @media (max-width: 992px){
        padding: 0 0;
   }
`;