import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, Icon } from "react-materialize";
import { FlexBox } from "../../styles";
import constants from "../../static";

export const Sidenav = () => {
    return(
        <div>
            <ul>
                <li>
                        <Link className="sidenav-bg-link sidenav-close" to='/seja-nosso-parceiro'>
                            Seja nosso Parceiro
                        </Link>
                </li>
                <li>
                    <FlexBox center>
                        <Link className="sidenav-bg-link sidenav-close" to='/acao-de-pesquisa-e-extensao'>
                            Ações de Pesquisa e Extensão
                        </Link>

                        <Dropdown
                            id="sidenav_drop"
                            trigger={<Icon  right>
                                        arrow_drop_down
                                    </Icon>}
                        >
                            <Link className="sidenav-bg-link sidenav-close" to='/acoes-da-prppg'>
                                Ações de Pesquisa
                            </Link>

                            <Link className="sidenav-bg-link sidenav-close" to='/acoes-de-extensao'>
                                Ações de Extensão
                            </Link>
                        </Dropdown>
                    </FlexBox>
                </li>
                <li>
                        <Link className="sidenav-bg-link sidenav-close" to='/contato'>
                            Contato
                        </Link>
                </li>
                <li>
                        <a className="sidenav-bg-link sidenav-close" href={constants.sistema}>
                            Login
                        </a>
                </li>
            </ul>
        </div>
    )
}